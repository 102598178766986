.Stripes      {
                left: 0px; top: 0px; width: 100%; height: 100%; border-radius: 2px;
                background-image: repeating-linear-gradient(-45deg, var(--n60), var(--n60) 25px, var(--high100) 25px, var(--high100) 50px);
                -webkit-animation:progress 2s linear infinite;
                -moz-animation:progress 2s linear infinite;
                -ms-animation:progress 2s linear infinite;
                animation:progress 2s linear infinite;
                background-size: 150% 100%;
              }

@-webkit-keyframes progress { 0% { background-position: 0 0; } 100%  { background-position: -75px 0px; } }
@-moz-keyframes progress    { 0% { background-position: 0 0; } 100% { background-position: -75px 0px; } }    
@-ms-keyframes progress     { 0% { background-position: 0 0; } 100% { background-position: -75px 0px; } }    
@keyframes progress         { 0% { background-position: 0 0; } 100% { background-position: -70px 0px; } }