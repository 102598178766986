.Item                   { display: flex; width: auto; height: auto; min-height: 15px; margin: 0px 0px;
                          padding: 0px; border-radius: 0px; border-bottom: 0px solid #EEEEEE; font-size: 13px; color: #555555;
                          cursor: pointer; }
.Item .Iconbox          { margin-right: 5px; }

.Item.Text .Labelrow.W100   { width: 100%;}

.Item.Thumb             { display:flex; flex-wrap: wrap; flex-basis:130px; height:50px; padding:5px 5px; overflow:hidden; 
                          border-radius:5px; border: 1px solid var(--bw-300); }
.Item.Thumb.Selected    { background:var(--pri500); }
.Item.Thumb .Col1       { display:flex; width:100%; flex-grow:0; flex-shrink:0; height:25px; }
.Item.Thumb .Col1 img   { display:flex; width:70px; flex-grow:0; flex-shrink:0; height:100%; }
.Item.Thumb .Col2       { display:flex; width:100%; justify-content:center; }
.Item.Thumb .Col2 .Text { font-size: 11px; }

.Item.Thumbh                        { position:relative; display:flex; flex-wrap: wrap; width:180px!important;; height:20px; flex-grow:0; flex-shrink:0; 
                                      margin-right: 20px!important; border-radius:0px; border: 0px solid var(--bw-300);  overflow:hidden; }
.Item.Thumbh.Selected               { background:transparent; border:0px solid var(--green); }
.Item.Thumbh .Col1                  { display:flex; height:20px; width:40px; flex-grow:0; flex-shrink:0; padding: 0px 0px; }
.Item.Thumbh .Col1 img              { display:flex; height:100%; width:100%; margin-top: 3px; object-fit: contain; }
.Item.Thumbh .Col2                  { display:flex; width:calc(100% - 75px); justify-content:left; flex-grow:0; flex-shrink:0; padding: 3px 0px; overflow:hidden; }
.Item.Thumbh .Col2 .Text            { margin: auto 0px; padding-left: 5px; font-size: 11px; white-space: nowrap; }

.Item.Field                         { flex-basis:100%; width: 100%; height: 20px; margin: 0px; flex-grow:0; flex-shrink:0; }
.Item.Field .Label                  { float:left; width: 80px; margin: 0px; padding: 2px 10px; color: var(--bw-700); font-weight: 400; }
.Item.Field .Value                  { float:left; width: auto; background:transparent; font-size: 12px; color: var(--bw-800); font-weight: 600; }

.Item .Value            { height: 24px; border-radius: 5px; padding: 4px 10px; font-size: 11px; font-weight: 400;
                          background: var(--pri500); color: var(--bw-900); }

.Item.Text              { width: auto; height: auto; margin: 0px 10px 10px 0px; }
.Item.Text.Selected  .Text          { color: var(--pri500); }
.Item.Text.L .Text      { font-size: 14px; }
.Dark .Item.Text.Selected  .Text          { color: var(--bw-000); }

.Item.Tag               { width: auto; height: 24px; margin: 0px 10px 10px 0px; }
.Item.Tag .Label        { margin: 5px 5px 0px 0px; }
.Item.Tag .Value        { background: var(--pri600); color: #FFFFFF; }

.Item.Image             { width: 150px; height: 80px; margin: 10px; padding: 0px; }
.ItemImageContainer     { position: relative; float: left; width: 100%; height: 80%; }
.ItemImage              { width: 100%; height: calc(100% - 30px); margin: 0px auto; text-align: center;}
.ItemImage img          { width: 100%; object-fit: contain!important; }
.ItemText               { width: 100%; height: 30px; }
.ItemText p             { margin: 0px!important; padding: 0px!important; font-size: 10px; text-align: center; text-transform: uppercase; font-weight: 700; }


.Item.Info              { display: flex; flex-wrap: wrap; position: relative; min-width: 165px; height: auto; margin: 10px; padding: 10px 10px; border-radius: 10px;
                          border: 1px solid var(--bw-200); background: var(--bw-000); }
.Item.Info.Innactive    { background: var(--bw-050); opacity: 1; }
.Item.Info.Selected     { background: var(--pri500); }
.Item.Info .ItemImage   { flex-basis: 100%; width: 100%; height: 20px;}
.Item.Info img          { width: 60%; height: 15px; margin: 0px auto; }
.Item.Info .ItemText    { height: 15px; margin-bottom: 10px; }
.Item.Info .ItemText p  { font-size: 10px; }
.Item.Info .InfoRow     { width: 100%; height: 20px; }
.Item.Info .InfoRow p   { text-align: center; font-size: 12px; font-weight: 600; }
.NTagRed                { border-radius: 10px; background: var(--red); color: #FFFFFF; }
.NTagGreen              { border-radius: 10px; background: var(--green); color: #FFFFFF; }

.Item.Values            { float: left; width: 100%; height: 15px; min-height: 15px; margin: 2px; padding: 0px; }
.Item.Values .Label     { float: left; width: 30px; margin: 0px 10px 0px 5px; padding: 0px; color: var(--bw-800); font-weight:400; }
.Item.Values .Text      { float: left; width: auto; margin: 0px 5px; padding: 0px; font-size: 11px; color: var(--bw-900); }

.Item.Title             { display: block; width: auto; height: auto; margin: 0px 20px 5px 0px; padding: 0px; }
.Item.Title p           { height: auto; min-height: auto; margin: 0px 0px 0px 5px; padding: 0px; font-size: 14px; color: var(--bw-900); }

.Item.Bold              { float: left; width: 100%; height: auto; margin: 0px; padding: 0px; }
.Item.Bold p            { height: auto; margin: 0px 0px 0px 5px; padding: 0px; font-size: 12px; color: var(--bw-900); font-weight: 600; }

.Item.Date              { display: flex; width: 60px; height: auto; margin: 0px 10px 10px 0px; border-radius: 5px; border: 0px solid var(--bw-100); }
.Item.Date .Label       { margin: 0px; font-size: 9px; color: var(--bw-800); }
.Item .Datebox          { width: 60px; height: 60px; background: transparent; }
.Item .Datemonth        { width: 100%; height: 15px; border-radius: 5px 5px 0px 0px; background: var(--pri500); }
.Secondary .Datemonth   { background: var(--bw-200); }
.Item .Datemonth p      { text-align: center; font-size: 11px; font-weight: 700; color: var(--bw-000); }
.Item .Dateday          { width: 100%; height: 30px; padding-top: 1px; background: var(--bw-000); }
.Item .Dateday p        { text-align: center; font-size: 18px; font-weight: 400; color: var(--bw-800); }
.Item .Dateweek         { width: 100%; height: 15px; padding-top: 1px; border-radius: 0px 0px 5px 5px; background: var(--bw-050); }
.Item .Dateweek p       { text-align: center; font-size: 9px; font-weight: 600; color: var(--bw-900); }
/* .Item.Date.Selected .Dateday    { background: var(--orange);} */

.Item.Imgtxt            { flex-basis:100%; width:100%; height:35px; padding:0px 20px; }
.Item.Imgtxt .Col1      { float:left; width: 50px; height: 100%; margin-right: 10px; padding: 1px 0px; }
.Item.Imgtxt .Col2      { display: table; float:left; width: calc(100% - 60px); height: 100%; }
.Item.Imgtxt .Text      { display: table-cell; vertical-align: middle; }

.Item.Imgtxtl           { flex-basis:100%; width:60px; height:35px; padding:10px 20px 5px 20px; }
.Item.Imgtxtl .Col1     { float:left; width: 50px; height: 100%; margin-right: 10px; padding: 1px 0px; }
.Item.Imgtxtl .Col2     { display: table; float:left; width: calc(100% - 70px); }
.Item.Imgtxtl .Text     { display: table-cell; vertical-align: middle; }

.Item.Imgtxtv           { flex-basis:100%; height:100%; padding:5px 5px; justify-content: flex-end; align-items: center; }
.Item.Imgtxtv .Col1     { width: 80px; height: 100%; margin-right: 0px; }
.Item.Imgtxtv .Col2     { display: none; margin-left: auto; margin-right: 10px; }
.Item.Imgtxtv:hover .Col2 { display: block; }
.Item.Imgtxtv .Text     { display: table-cell; vertical-align: middle; }

/* 
.Item.onDragOver        { background: red; }
.Item.onDrag            { background: blue; } */

/* .Item .Edit             { float: left; width: 25px; height: 35px; padding: 5px 0px; cursor: move; }
.Item .Icon2            { float: left;  width: 35px; height: 35px; border-radius: 50%;  margin: 0px 10px 0px 0px; padding: 10px 0px;
                              background:#EEEEEE; text-align: center; color: #999999; font-size: 10px; font-weight: 700; }
.Item a                 { float: left; width: 100%; height: 100%; }
.Item .Text             { float: left; width: calc(100% - 50px); height: 100%; padding-top: 5px;
                          font-size: 14px; font-weight: 400; text-align: left; overflow: hidden; }
.Item.Label .Text       { width: 100%; text-align: center; }
.Item .Text:hover       { overflow: hidden; }
.Item .Text2            { float: left; width: calc(100% - 50px); font-size: 10px;  }
.Item  span             { font-weight: 200; }
.Item img               { float: left; width: 40px; height: 40px; object-fit: cover; }
.Item.L img             { width: 100px; height: 100px; object-fit: cover; border-radius: 50%; } */

/* .Item .Icon2            { background-image: linear-gradient(to right, #D7DDE8 0%,  #757F9A 100%); } */
/* .Item .Icon2            { background-image: linear-gradient(to right, #ED4264 0%,  #FFEDBC 100%); } */
/* .btn-grad:hover             { background-position: right center; }
.btn-grad:hover             { background-position: right center; } */

/* .Grid .Item             { max-width: 150px; } */
