.Section                        { position: relative; display: flex; width: 100%; flex-direction: row; flex-wrap: wrap; justify-content: flex-start;
                                  align-items: flex-start; align-content: flex-start; background: transparent; margin: 0px; padding: 0px; outline: none;
                                  -webkit-tap-highlight-color: transparent; overflow: visible; }
.Section.Hscroll                { width: 100%; flex-wrap: nowrap; padding-bottom: 10px; overflow-x: auto; overflow-y: hidden; }

.Content                        { margin: 0px auto; max-width: var(--max-width); }
.Webapp .Content                { width: 100%; max-width: 100%; height: 100%; margin: 0px; overflow: visible; }

.Header                         { flex-basis: 100%; height: 50px; border-bottom: 1px solid var(--bw-050); overflow-y: visible; }
.Header .Content                { padding: 0px 20px; }
.Website .Header                { position: fixed; top: 0px; left: 0px; height: 90px; background: var(--bw-000); z-index: 10; }

.Footer                         { height: 30px; background: var(--bw-800); color: var(--bw-200); }
.Footer .Content                { padding: 10px 20px;}
.Website .Footer                { height: auto; }
.Website .Footer .Content       { min-height: 200px; padding: 40px 40px; color: var(--bw-050); }

.Page                           { flex: auto; width: 100%; background: #F3F3F3; overflow: hidden; }
.Webapp .Page                   { height: calc(100vh - 80px); }
.Website .Page                  { min-height: 600px; }
.Website .LoginPage             { min-height: 800px; }

.Sidenav                        { width: 150px; height: 100%; color: var(--bw-100); margin: 0px; transition: width .2s; background: var(--bw-800); overflow: hidden; }
.Sidenav.Closed                 { width: 50px; }

.Sidepanel                      { height: 100%; overflow: auto; padding: 0px 30px 20px 30px; }
.Tabpanel                       { width: calc(100% - 0px); height: calc(100% - 30px); margin: 10px 0px; padding: 10px 10px; }
.Panel                          { width: calc(100% - 0px); margin: 10px 00px 10px 10px; padding: 10px 10px; }
.Boxpanel                       { width: calc(100% - 0px); margin: 10px 0px 0px 0px; padding: 20px 20px; border-radius: 10px;
                                  background: var(--bw-000); box-shadow: 7px 7px 7px 1px rgba(0, 0, 0, 0.1); }
.Bar                            { height: 30px; min-height: 25px; margin-bottom: 0px; padding: 0px 0px; border-bottom: 0px solid var(--bw-050); }

.Wide                           { width: auto; min-width: var(--min-width); height: 100%; margin: 0px auto; overflow: hidden; }
.Long                           { height: auto; min-height: 100%; }
.Column                         { width: 33.33%; height: calc(100% - 0px); padding: 0px; }
.Row                            { width: 100%; padding: 20px; }

.Boxpanel.Compact               { padding: 0px 0px; }
.Border                         { border-radius: 10px; border: 1px solid var(--bw-200); }
.Borderright                    { border-right: 1px solid var(--bw-200); }
.Margin                         { padding: 10px 20px; }
.Padding                        { padding: 10px 10px; }
.Divide                         { width: 100%; height: 1px; margin: 20px 0px; border-bottom: 1px solid var(--bw-200); }
.Vdivide                        { width: 1px; height: 100%; margin: 0px 20px; border-right: 1px solid var(--bw-200); }

.White                          { background: var(--bw-000)!important; }
.Grey                           { background: #B7D1DA; }
.Shadow                         { box-shadow: 7px 7px 7px 1px rgba(0, 0, 0, 0.1); }
.WhiteShadow                    { border-radius: 10px; background: #FFFFFF; box-shadow: 7px 7px 7px 1px rgba(0, 0, 0, 0.1); }

.Centerh                        { justify-content: center; }
.Centerv                        { align-content: center!important; margin-top: auto; margin-bottom: auto; }
.Right                          { justify-content: flex-end; }
.Scroll                         { overflow: scroll; }

.Title                          { width: 100%; height: 20px; margin-top: 0px; }
.Title h3                       { width: 100%; padding-left: 5px; text-align: left!important; font-size: 14px; color: var(--bw-700); font-weight: 400; }

.BreadDiv                       { float: left; height: 25px; }
.Bread,
.BreadSlash                     { width: auto; margin: 10px 5px; font-size: 12px; color: var(--bw-800); cursor: pointer; }
.Bread                          { font-weight: 700; }
.Bread:last-child               { background: none; font-weight: 400; cursor: inherit; }
.BreadSlash                     { margin: 10px 0px; font-size: 12px;  cursor: inherit; }



.Section .Loading               { border-radius: 10px; height: 100%; }
.Error                          { width: 500px; height: auto; min-height: 100px; max-height: 400px; margin: auto; justify-content: center; align-items: center;
                                  padding: 20px; border: 1px solid var(--red); border-radius: 10px; color: var(--red); }
.Error h2, .Error p             { width: 100%; text-align: center;}

.Box                            { width: 420px; height: 300px; margin: 20px 20px; padding: 20px 20px;
                                  border-radius: 10px; box-shadow: 7px 7px 7px 1px rgba(0, 0, 0, 0.1);
                                  background: var(--bw-000); }



.Section .SectionBack           { flex-basis: 100%; justify-content: center; }
.Section .SectionBack .Icon.top { margin: 0px auto; }


.Expand                         { width: 100%!important; height: 100%!important; }

.WebApp .Section h2             { flex-basis: auto; width: auto; margin: 0px 0px; padding: 0px; font-size: 18px; text-align: left; }
.WebApp .Section h3             { flex-basis: auto; width: auto; margin: 0px 0px; padding: 0px; font-size: 14px; font-weight: 600; text-align: left; }
.WebApp .Section.Error h2       { margin: 40px auto; font-weight: 400; text-transform: uppercase; }
.Empty .Icon                    { margin: 0px auto; }
.Section .Error p               { margin: 10px; font-size: 16px; text-transform: uppercase; }
/* .Section p                      { padding: 10px; } */

.Section .BackgroundImage       { position: absolute; height: 100%; width: 100%; top: 0px; left: 0px;
                                  object-fit: cover; object-position: 50% 50%; z-index: 0; }
.Section img                    { position: relative; }
.Overlay                        { position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: #000000; opacity: .25;  z-index: 1;  }

@media only screen and (max-width: 950px) {
    /* .Section { width: 100%; } */
}