/*
Top screen resolutions:
  1	1920×1080	104,190(22.62%)
  2	1366×768	51,580(11.20%)
  3	1440×900	44,003(9.55%)
  4	1536×864	39,606(8.60%)
  5	2560×1440	34,152(7.41%)

  Cardiologists screen: 1920 x 1200
*/

/*
  Webapp fonts: 'Helvetica Neue', 'Open Sans'
  Website fonts: 'Circular', 'Roboto'

  https://www.dfonts.org/fonts/circular-font-family/
  https://stackoverflow.com/questions/41676054/how-to-add-fonts-to-create-react-app-based-projects
*/

/* *                                                       { font-family: 'Poppins', sans-serif; } */
*                                                       { box-sizing: border-box; margin: 0px; padding: 0px; }
a, a:hover                                              { text-decoration: none; cursor: pointer; }
a                                                       { font-weight: 300; font-weight: 400; }
li                                                      { list-style-type: none; font-weight: 400; }

::-webkit-scrollbar                                     { width: 10px; height: 10px; }
.noscrollbar::-webkit-scrollbar                         { height: 0px; }
::-webkit-scrollbar-track                               { background: var(--grey300); }
::-webkit-scrollbar-thumb                               { background: var(--grey400); }
::-webkit-scrollbar-thumb:hover                         { background: var(--grey500); }

input:focus, textarea:focus, button                     { outline: none; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active                           { -webkit-box-shadow: 0 0 0px 1000px white inset; }

/* input::-webkit-credentials-auto-fill-button             { display: none; } */
input::-webkit-credentials-auto-fill-button             { margin: 0; width: 0; background-color: transparent; }

.Hover                    { display: none!important; }
.Hover:hover              { display: flex!important; }
.no_select                { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

html                      { width: 100%; scroll-behavior: smooth; max-width: 100%; overflow-x: hidden; }

body                      { width: 100%; height: 100vh; max-width: 100%; margin: 0; font-weight: 400;
                            -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; overflow: overlay; }

p                         { width: auto; font-size: 18px; text-align: center; font-weight: 400; line-height: 1.5; text-align: left; }
span                      { font-weight: 400; }
button                    { background: transparent; border: 0px; }
img                       { user-select: none; }

.Tooltip                  { display: block; position: absolute; width: 150px; height: 40px;
                            padding: 5px 10px; border-radius: 10px; border: 1px solid var(--grey500);
                            background: var(--grey500); color: #FFFFFF; text-align: center;
                            font-size: 12px; z-index: 100;  opacity: 0.85; transition: opacity 3s; }
.Tooltip p                { margin: 0px!important; padding: 0px!important; font-size: 13px; text-align: center; line-height: 1.2; }


.TooltipChart             { position: absolute; width: 150px; height: auto; min-height: 25px; padding: 4px;
                            border: 1px solid var(--grey050); border-radius: 0px; border: 0px solid var(--grey100);
                            background: white; overflow: visible; opacity: .9; z-index: 10; }
.TooltipChart:hover       { opacity: 1.;  z-index: 11;}
.TooltipChart p           { float:left; width: 100%; text-align: center; font-size: 11px; color: var(--grey900); font-weight: 600; }
.PointerTop               { position: absolute; top: 13px; left: calc(50% - 15px); }
.PointerBottom            { position: absolute; top: -8px; left: calc(50% - 15px); height: 15px; }
.PointerRight             { position: absolute; top: calc(50% - 10px); left: -7px; }
.PointerCenter            { position: absolute; top: calc(50% - 5px); left: 0px; }

.Shadow                   { box-shadow: 7px 7px 7px 1px rgba(0, 0, 0, 0.1); }

/* https://material.io/design/color/the-color-system.html#tools-for-picking-colors */

:root {
  --min-width: 1250px;
  --max-width: 2500px;
  --max-text-width: 1000px;

  --white: #FFFFFF;
  --black: #000000;

  --green: #009C22;
  --green-light: #F1FFEB;
  --green-intense: #00e676;

  --red: #CF0000;  /* #EE6F69; */
  --red-light: #FFF0F0;
  --red-intense: #00e676;

  --orange: #DE7D00;

  --n00: #FFFFFF;
  --n20: #F4F4F6;
  --n40: #E9EAEC;
  --n60: #DCDDE1;
  --n80: #90939E;
  --n100: #757781;

  --pri40: #77ABBD;
  --pri80: #313B63;
  --pri100: #222945;
  --pri120: #1F253E;
  --pri140: #1B2137;
  --pri160: #1A1F34;


  --sec60: #EBF2F5;
  --sec80: #F4F9FB;
  --sec100: #D5E5EB;

  --high60: #F0F5FF;
  --high80: #DAE7FE;
  --high100: #0542AD;
  --high120: #043893;
  --high140: #032A6D;

  --success: #009C22;
  --fail: #CF0000;


  --primary1: #203588;
  --primary2: #0F2168;
  --grey1: #F8F9FB;
  --grey2: #F3F3F3;
  --grey3: #E3E3E3;

  --dark: #222945;
  --darkselect: #77ABBD;
  --lightgrey: #F8F8F8;
  --blue: #77ABBD;
  --blue-intense: #3171d9;
  --yellow: #F9A825;
  --grey: #455A64;
  --blue-grey: #CBD5E0;

  --chart-red: #A5402D;
  --chart-green: #49a078;
  --chart-yellow: #db9d47;
  --chart-neutral: #6f8695;

  --grey050: #fafafa;
  --grey100: #f5f5f5;
  --grey200: #eaeaea;
  --grey300: #e0e0e0;
  --grey400: #bdbdbd;
  --grey500: #9e9e9e;
  --grey600: #757575;
  --grey700: #616161;
  --grey800: #424242;
  --grey900: #212121;

  --red050: #FFEBEE;
  --red100: #FFCDD2;
  --red200: #EF9A9A;
  --red300: #E57373;
  --red400: #EF5350;
  --red500: #F44336;
  --red600: #E53935;
  --red700: #D32F2F;
  --red800: #C62828;
  --red900: #B71C1C;

  --pink050: #FCE4EC;
  --pink100: #F8BBD0;
  --pink200: #F48FB1;
  --pink300: #F06292;
  --pink400: #EC407A;
  --pink500: #E91E63;
  --pink600: #D81B60;
  --pink700: #C2185B;
  --pink800: #AD1457;
  --pink900: #880E4F;

  --purple050: #EDE7F6;
  --purple100: #D1C4E9;
  --purple200: #B39DDB;
  --purple300: #9575CD;
  --purple400: #7E57C2;
  --purple500: #673AB7;
  --purple600: #5E35B1;
  --purple700: #512DA8;
  --purple800: #4527A0;
  --purple900: #311B92;

  --deeppurple050: #F3E5F5;
  --deeppurple100: #E1BEE7;
  --deeppurple200: #CE93D8;
  --deeppurple300: #BA68C8;
  --deeppurple400: #AB47BC;
  --deeppurple500: #9C27B0;
  --deeppurple600: #8E24AA;
  --deeppurple700: #7B1FA2;
  --deeppurple800: #6A1B9A;
  --deeppurple900: #4A148C;

  --indigo050: #E8EAF6;
  --indigo100: #C5CAE9;
  --indigo200: #9FA8DA;
  --indigo300: #7986CB;
  --indigo400: #5C6BC0;
  --indigo500: #3F51B5;
  --indigo600: #3949AB;
  --indigo700: #303F9F;
  --indigo800: #283593;
  --indigo900: #1A237E;

  --blue050: #E3F2FD;
  --blue100: #BBDEFB;
  --blue200: #90CAF9;
  --blue300: #64B5F6;
  --blue400: #42A5F5;
  --blue500: #2196F3;
  --blue600: #1E88E5;
  --blue700: #1976D2;
  --blue800: #1565C0;
  --blue900: #0D47A1;

  --lightblue050: #E1F5FE;
  --lightblue100: #B3E5FC;
  --lightblue200: #81D4FA;
  --lightblue300: #4FC3F7;
  --lightblue400: #29B6F6;
  --lightblue500: #03A9F4;
  --lightblue600: #039BE5;
  --lightblue700: #0288D1;
  --lightblue800: #0277BD;
  --lightblue900: #01579B;

  --cyan050: #EDFDFD;
  --cyan100: #C4F1F9;
  --cyan200: #76E4F7;
  --cyan300: #76E4F7;
  --cyan400: #0BC5EA;
  --cyan500: #00B5D8;
  --cyan600: #00A3C4;
  --cyan700: #0987A0;
  --cyan800: #086F83;
  --cyan900: #065666;

  --teal050: #E0F2F1;
  --teal100: #B2DFDB;
  --teal200: #80CBC4;
  --teal300: #4DB6AC;
  --teal400: #26A69A;
  --teal500: #009688;
  --teal600: #00897B;
  --teal700: #00796B;
  --teal800: #00695C;
  --teal900: #004D40;

  --green050: #E8F5E9;
  --green100: #C8E6C9;
  --green200: #A5D6A7;
  --green300: #81C784;
  --green400: #66BB6A;
  --green500: #4CAF50;
  --green600: #43A047;
  --green700: #388E3C;
  --green800: #2E7D32;
  --green900: #1B5E20;

  --lightgreen050: #F1F8E9;
  --lightgreen100: #DCEDC8;
  --lightgreen200: #C5E1A5;
  --lightgreen300: #AED581;
  --lightgreen400: #9CCC65;
  --lightgreen500: #8BC34A;
  --lightgreen600: #7CB342;
  --lightgreen700: #689F38;
  --lightgreen800: #558B2F;
  --lightgreen900: #33691E;

  --lime050: #F9FBE7;
  --lime100: #F0F4C3;
  --lime200: #E6EE9C;
  --lime300: #DCE775;
  --lime400: #D4E157;
  --lime500: #CDDC39;
  --lime600: #C0CA33;
  --lime700: #AFB42B;
  --lime800: #9E9D24;
  --lime900: #827717;

  --yellow050: #FFFDE7;
  --yellow100: #FFF9C4;
  --yellow200: #FFF59D;
  --yellow300: #FFF176;
  --yellow400: #FFEE58;
  --yellow500: #FFEB3B;
  --yellow600: #FDD835;
  --yellow700: #FBC02D;
  --yellow800: #F9A825;
  --yellow900: #F57F17;

  --amber050: #FFF8E1;
  --amber100: #FFECB3;
  --amber200: #FFE082;
  --amber300: #FFD54F;
  --amber400: #FFCA28;
  --amber500: #FFC107;
  --amber600: #FFB300;
  --amber700: #FFA000;
  --amber800: #FF8F00;
  --amber900: #FF6F00;

  --orange050: #FFF3E0;
  --orange100: #FFE0B2;
  --orange200: #FFCC80;
  --orange300: #FFB74D;
  --orange400: #FFA726;
  --orange500: #FF9800;
  --orange600: #FB8C00;
  --orange700: #F57C00;
  --orange800: #EF6C00;
  --orange900: #E65100;

  --deeporange050: #FBE9E7;
  --deeporange100: #FFCCBC;
  --deeporange200: #FFAB91;
  --deeporange300: #FF8A65;
  --deeporange400: #FF7043;
  --deeporange500: #FF5722;
  --deeporange600: #F4511E;
  --deeporange700: #E64A19;
  --deeporange800: #D84315;
  --deeporange900: #BF360C;

  --brown050: #EFEBE9;
  --brown100: #D7CCC8;
  --brown200: #BCAAA4;
  --brown300: #A1887F;
  --brown400: #8D6E63;
  --brown500: #795548;
  --brown600: #6D4C41;
  --brown700: #5D4037;
  --brown800: #4E342E;
  --brown900: #3E2723;

  --bluegray050: #ECEFF1;
  --bluegray100: #CFD8DC;
  --bluegray200: #B0BEC5;
  --bluegray300: #90A4AE;
  --bluegray400: #78909C;
  --bluegray500: #607D8B;
  --bluegray600: #546E7A;
  --bluegray700: #455A64;
  --bluegray800: #37474F;
  --bluegray900: #263238;

  --sec900: #E65100;
  --sec800: #EF6C00;
  --sec700: #F57C00;
  --sec600: #FB8C00;
  --sec500: #FF9800;
  --sec400: #FFA726;
  --sec300: #FFB74D;
  --sec200: #FFCC80;
  --sec100: #FFE0B2;
  --sec050: #FFF3E0;

  --chart1: #2bcb95;
  --chart2: #ffc100;
  --chart3: #e19348;
  --chart4: #f383a2;
  --chart5: #ff6b3b;
  --chart6: #8e283b;
  --chart7: #76523b;
  --chart8: #b1abf4;
  --chart9: #1d42c2;
  --chart10: #247fea;
  --chart11: #1d9ed1;
  --chart12: #9fb40f;
}


/* --B20: #333C4B;
--B30: #3B434E;
--F30: #EEEEEE;

--BG1: #FFFFFF;
--BG2: #F6F6F6;
--BH3: #E2E1E2;
--BLACK: #000000;
--BLUE: #39BCE0;
--DARK-BLUE: #0F79AD;
--BLUE1: #61C0DF;
--BLUE2: #0288D1;
--GREEN: rgb(15, 179, 129);
--DARK-GREEN: #0C9C8C;
--ORANGE: #c26956;
--ORANGE1: #E64A19; */